import { getAssetByIdForSection } from '../../../get/getAsset'
import { getAttributeFromWindow } from '../../../get/getAttribute'
import CauliflowerModal from '../../../../CauliflowerModal'
import { findActiveSectionName, findActiveSection } from '../toolbox.classroom'
import { renderAssetForModal } from '../render/renderAssetForModal'
import googleClassroom from '../googleClassroom'
import openToast from './openToast'
import $ from 'jquery'

const handleOpenAssetModal = ({ id }) => {
  const section = findActiveSection()
  const asset = getAssetByIdForSection(id, section.id)
  const lesson = findActiveSectionName()
  const body = renderAssetForModal(asset, lesson)
  const ireadyServer = getAttributeFromWindow('IREADY_SERVER')
  const googleClassroomEnabled = getAttributeFromWindow('IS_GOOGLE_CLASSROOM_ENABLED')
  const showGoogleClassroom = asset.attachmentExtension === 'pdf'

  let options = {
    title: asset.title,
    asset: asset,
    section: section,
    className: 'asset-modal asset-modal--is-classroom',
    showTermsOfUse: true,
    body,
    onClose: function () {
      collapseStandards()

      if ((asset.audience === 'Student' || asset.originalAudience === 'student') && googleClassroomEnabled && showGoogleClassroom) {
        googleClassroom.resetModal(asset.id)
      }
    }
  }

  if (ireadyServer === 'pd') {
    if ((asset.link && asset.link.includes('desmos')) || (asset.toolUrl && asset.toolUrl.includes('desmos'))) {
      options = {
        title: 'About this Tool',
        className:
          'asset-modal asset-modal--is-classroom asset-modal--is-small asset-modal--is-pd',
        showTermsOfUse: false,
        body
      }
    }
  }

  CauliflowerModal.findOrCreate(options, asset.id)
  if ((asset.audience === 'Student' || asset.originalAudience === 'student') && googleClassroomEnabled && showGoogleClassroom) {
    googleClassroom.initClassroom(asset.id)
  }

  if (asset.alternates.length > 0 && asset.alternates.filter(a => a.key === 'google')) {
    const modalSelector = '[data-cauliflower-modal="' + asset.id + '"]'
    const $modal = $(modalSelector)
    const urlParams = new URLSearchParams(window.location.search)
    const isRedirect = urlParams.get('isRedirect')
    const isDownload = urlParams.get('isDownload')

    if (googleClassroomEnabled) {
      googleClassroom.callApi(googleClassroom.apiEndpoints.getUserStatus).done(function (result) {
        // $modal.find('.asset-for-modal__link.google-classroom-link').show()
        if (result.code === 201) {
          $modal.find('.asset-for-modal__google-classroom-banner').hide()
          $modal.find('.tooltip').addClass('tooltip--hidden')
          $modal.find('.google-drive-asset__link').attr('data-action', 'add-to-google-drive')

          if (isDownload && $modal.find('.google-drive-asset__link').length > 0) $modal.find('.google-drive-asset__link').first().trigger('click')
        } else {
          if (result.code === 401 && isRedirect === 'true') {
            const toastData = {
              type: 'warning',
              title: 'Authentication Unsuccessful',
              message:
                'Teacher Toolbox could not authenticate with Google services. Please check all of the permissions boxes to allow access to both Google Drive and Google Classroom and try again.',
              showDismiss: true
            }
            openToast(toastData)
          }
          $modal.find('.asset-for-modal__google-classroom-banner').show()
          $modal.find('.google-drive-asset__link').attr('data-action', 'assign-to-google-classroom').attr('data-download', 'true')
        }

        googleClassroom.showHideLoader($modal, false)

        urlParams.delete('asset')
        urlParams.delete('assetType')
        window.history.replaceState(
          {},
          '',
          `${window.location.pathname}` +
            (urlParams.length > 0 ? `${urlParams}` : ``) +
            `${window.location.hash}`
        )
      })
    } else {
      $modal.find('.asset-for-modal__google-classroom-banner').hide()
      $modal.find('.asset-for-modal__alternate.google').hide()
    }
  }

  CauliflowerModal.open(asset.id, 'asset', section.id)
}

var collapseStandards = function () {
  let collapseToggles = document.querySelectorAll('.asset-for-modal__accordion-panel-toggle')
  collapseToggles.forEach((toggle, index) => {
    toggle.checked = false
  })
}

export default handleOpenAssetModal
